.SignIn{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.SignIn .signin-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
}

.SignIn .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SignIn .image{
    max-width: 200px;
}

.SignIn .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.SignIn .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SignIn .footer{
    text-align: center;
    padding-top: 20px;

}

.SignIn .recover{

}

.SignIn .recover a{
    margin-bottom: 20px;
    cursor: pointer;
}

.SignIn .login-button button{
    min-width: 300px;
}
