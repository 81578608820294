.SEPMap {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}

.SEPMap .map-stack {
    display: flex;
    flex: 1;
    position: relative;
}

.SEPMap .map-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
}

.SEPMap #sep-map-google{
    -webkit-user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.SEPMap #sep-map-leaflet {
    -webkit-user-select: none;
    display: flex;
    flex: 1;
}

/*
    the leaflet-image-layer class is created by the nontiled plugin
    https://github.com/ptv-logistics/Leaflet.NonTiledLayer
    the z-index of this div cannot be set to 0 programmatically, so a CSS override is needed
*/
.SEPMap .leaflet-image-layer{
    z-index: 0;
}

/*handle special css cases based on which map-layer is selected*/
.map-layer.leaflet.selected-map-layer-google #sep-map-leaflet{
    background: transparent;
}

/*layers*/
.overlay-layer.controls {
    position: absolute;
    right: 0;
}

.overlay-layer.dashboard {

}

.sep-building-selection-marker.invisible{
    display: none;
}
