.Controls{

}

.Controls .control{
    display: flex;
    justify-content: flex-end;
}

.Controls .control .MapSelector .row{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.Controls .control .MapSelector .row button{
    min-height: 50px;
}

.Controls .MapSelectionControl .row{
    display: flex;
    justify-content: flex-end;
}

.Controls .MapSelectionControl .row .selector{
    display: flex;
}
.Controls .MapSelectionControl .row .selector button{
    max-width: 100px;
    padding: 8px;
}

.Controls .control .AdvancedControls .row{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.Controls .control .AdvancedControls>.row button{
    min-width: 50px;
    align-items: center;
    justify-content: center;
}

.Controls .control .AdvancedControls .Share{
    position: relative;
}
.Controls .control .AdvancedControls .Share #share-clipboard-textarea{
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: 45px;
}

.Controls .control.trash-selection button{
    display: inline-block;
    min-width: 50px;
    padding: 8px;
}
.Controls .control.trash-selection button i,
.Controls .control.trash-selection button span{
    font-size: 0.8em;
}

#root .Controls .control button{
    min-width: 50px;
}
#root .Controls .control div{
    min-width: 50px;
}
