html{
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
body{
    position: fixed;
    overflow: hidden;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}
#root, .App{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}
