.Home{
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.Home .content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Home .menu-content-wrapper .container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
}

.Home .menu-content-wrapper .image{
    max-width: 200px;
}

.Home .menu-content-wrapper .heading .image{
    display: flex;
    margin: 0 auto;
    text-align: center;
}
.Home .menu-content-wrapper .heading h1 {
    text-align: center;
}

.Home .menu-content-wrapper .button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#root .Home .menu-content-wrapper .button-container button{
    max-width: 300px;
    width: 100%;
    margin-top: 15px;
}
